<template>
    <div :class="theme.auth.auth">
        <ToggleTheme/>
        <div :class="theme.auth.subcontainer">
            <form @submit.prevent="login">
                <LogoBlock />
                <div :class="theme.auth.header">
                    <span :class="theme.auth.text">
                        Sign In
                    </span>
                </div>
                <div :class="theme.auth.content">
                    <TextInput
                        title="Email"
                        placeholder="Your email"
                        :value="loginData.email"
                        @input-text="loginData.email = $event; errors.email = false"
                        :class="theme.auth.mbL"
                        :has-error="errors.email"
                        error-text="Please enter valid email"
                    />
                    <TextInput
                        is-password
                        title="Password"
                        placeholder="Your password"
                        :value="loginData.password"
                        @input-text="loginData.password = $event; errors.password = false"
                        :class="theme.auth.mbL"
                        :has-error="errors.password"
                        error-text="Password min length is 8 letters.<br>Password max length is 100 letters"
                        error-text-bottom
                    />
                    <div :class="theme.auth.checkboxRow">
                        <Checkbox
                            :value="loginData.keepMeSignedIn"
                            @state-changed="loginData.keepMeSignedIn = !loginData.keepMeSignedIn"
                        >
                            <template>
                                Keep me signed in
                            </template>
                        </Checkbox>
                        <router-link to="/restorepassword">
                            Forgot password?
                        </router-link>
                    </div>
                    <Button
                        is-submit
                        button-type="primary"
                        :button-disabled="isSignInSubmitDisabled"
                        wide
                        :class="theme.auth.mbL"
                    >
                        <template>
                            Sign in
                        </template>
                    </Button>
                    <div :class="theme.auth.center">
                        <router-link :class="theme.auth.center" to="/signup">
                            Don't have an account? Sign-Up
                        </router-link>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, email, maxLength, minLength } from 'vuelidate/dist/validators.min';

import theme from 'Theme';
import ToggleTheme from '@/components/Auth/components/ToggleTheme.vue';
import LogoBlock from '@/components/Auth/components/LogoBlock.vue';
import { getActivePresets, getPresetsList, makeDefaultPresets } from 'Store/v2/Presets';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import { getErrorMessage } from 'Store/modules/Auth';
import TextInput from 'Control/TextInput.vue';
import Button from 'Control/Button.vue';
import Checkbox from 'Control/Checkbox.vue';

export default {
    name: 'SignIn',
    components: {
        Checkbox,
        ToggleTheme,
        LogoBlock,
        TextInput,
        Button,
    },
    data() {
        return {
            loginData: {
                email: '',
                password: '',
                keepMeSignedIn: false,
            },
            errors: {
                email: false,
                password: false,
            },
            hiddenPassword: true,
            isSignInSubmitDisabled: false,
            theme,
        };
    },
    computed: {
        ...mapGetters({
            isMfaFactorActive: 'Auth/isMfaFactorActive',
            isEmailVerified: 'Auth/isEmailVerified',
        }),
    },
    validations() {
        return {
            loginData: {
                email: {
                    required,
                    email,
                    maxLength: maxLength(100),
                    minLength: minLength(5),
                },
                password: {
                    required,
                    maxLength: maxLength(72),
                    minLength: minLength(8),
                },
            },
        };
    },
    methods: {
        togglePassword(variant) {
            if (variant) {
                document.getElementById('passwordSignIn').getElementsByTagName('input')[0].type = 'text';
                this.hiddenPassword = false;
            } else {
                document.getElementById('passwordSignIn').getElementsByTagName('input')[0].type = 'password';
                this.hiddenPassword = true;
            }
        },
        async login() {
            this.loginData.email = this.loginData.email.trim();
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.isSignInSubmitDisabled = true;
                this.$store.commit(SET_LOADING_ON(undefined));

                try {
                    await this.$store.dispatch('Auth/login', this.loginData);
                    if (this.isMfaFactorActive) {
                        await this.$router.push('/signin-confirm');
                    } else if (!this.isEmailVerified) {
                        await this.$router.push('/signup-confirm');
                    } else {
                        await this.$store.dispatch('User/getCurrentUser');
                        await this.$store.dispatch(makeDefaultPresets(undefined));
                        await this.$store.dispatch(getPresetsList(undefined));
                        await this.$store.dispatch(getActivePresets(undefined));
                        await this.$store.dispatch('initInterface');
                        await this.$router.push('/terminal');
                    }
                } catch (error) {
                    const { errorMessage, needLogOut } = getErrorMessage(error);
                    await this.$store.dispatch('Notificator/showErrorNotification', errorMessage);
                    this.isSignInSubmitDisabled = false;
                    if (needLogOut) {
                        await this.$store.dispatch('Auth/logout', undefined);
                    }
                } finally {
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
            } else {
                if (this.$v.loginData.email.$invalid) {
                    this.errors.email = true;
                }
                if (this.$v.loginData.password.$invalid) {
                    this.errors.password = true;
                }
            }
        },
    },
    mounted() {
        if (this.$store.getters['Auth/isLogged']) {
            this.$router.push('/terminal');
        }
    },
};
</script>
